<template>
<!-- 客户分群设置 -->
<div class="box100">
  <div class="whiteBg">
    <template v-if="roleBtn[0].power">
      <div class="el-icon-plus settingAdd" @click="handleAdd"><span> 新增</span></div>
    </template>
    <div class="flexAC" style="margin: 16px 0px;">
      <el-input v-model="gcname"  placeholder="请输入分组名称" class="inputSearch"></el-input>
      <el-button class="btnSearch" @click="handleSearch">搜索</el-button>
      <el-button class="btnReset" @click="handleReset">重置</el-button>
    </div>
    <div class="card_list flexF">
      <el-card class="cardBox" v-for="(item, index) in groupList" :key="index">
        <p class="cardTitle">群体名称</p>
        <p class="cardDes">{{ item.ugname }}</p>
        <p class="cardTitle" style="margin-top: 20px;">群体说明</p>
        <p class="cardDes">{{ item.ugmark }}</p>
        <div class="flexCB" style="margin-top: 30px;">
          <template v-if="roleBtn[1].power">
            <i class="el-icon-delete" style="color: #F44C8E;cursor: pointer" @click="handDel(item)"></i>
          </template>
          <template v-if="roleBtn[1].power">
            <p style="color: #3C84EF;cursor: pointer" @click="handEdit(item)">规则设置</p>
          </template>
        </div>
      </el-card>
    </div>
  </div>

  <!-- 新增编辑弹框 -->
  <el-dialog :title="formTitle" :visible.sync="formShow" width="600px" center class="gyDialog" :close-on-click-modal="false" :before-close="formCancel">
    <el-form :model="groupForm" :rules="groupRule" ref="groupLabel">
      <el-form-item label="群体名称" prop="ugname">
        <el-input v-model="groupForm.ugname"  placeholder="请输入群体名称" maxlength="20"></el-input>
      </el-form-item>
      <el-form-item label="群体说明" prop="ugmark">
        <el-input class="formTextarea" v-model="groupForm.ugmark" type="textarea" maxlength="200" placeholder="请输入群体说明（最多200字）"></el-input>
      </el-form-item>
      <el-form-item class="labelItem" label="规则设定" prop="ugset">
        <div class="flexAC">
          <el-select v-model="ruleValue" placeholder="请选择规则" @change="changeRule">
            <el-option v-for="item in ruleList" :key="item.index" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <el-input v-model="ruleTime"  placeholder="请输入天数" maxlength="20" style="width:30%;margin: 0 10px;"></el-input>
          <p class="zdyAddBtn" @click="ruleAdd">添加</p>
        </div>
      </el-form-item>
      <div class="flexAC tagBox">
        <el-tag v-for="(tag,index) in groupForm.ugset" :key="index" closable type="info" @close="ruleDel(index)">
          <span><span style="color: #3C84EF;">{{ tag.time }}</span>天内</span>
          <span style="color: #3C84EF;margin-left: 5px;">{{ tag.name }}</span>
        </el-tag>
      </div>
    </el-form>
    <!--    -->
    <div class="flexCE" style="margin-top: 20px;">
      <div class="settingCancel" @click="formCancel">取消</div>
      <div class="settingConfirm" @click="groupConfirm">确定</div>
    </div>
  </el-dialog>
</div>
</template>

<script>
import { customerGroupQuery, customerGroupAdd, customerGroupEdit, customerGroupDel } from '@/api/customer'

export default {
  name: 'customerGroup',
  props: ['roleBtn'],
  data () {
    return {
      gcname: '',
      groupList: [],
      formShow: false,
      formTitle: '新增',
      groupForm: {
        ugid: '',
        ugname: '',
        ugmark: '',
        ugset: []
      },
      groupRule: {
        ugname: [
          { required: true, trigger: 'blur', message: '群体名称不能为空' },
          { min: 1, max: 20, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        ugmark: { required: true, trigger: 'blur', message: '群体说明不能为空' },
        ugset: { required: true, trigger: 'blur', message: '请设置规则' }
      },
      ruleList: [{ label: '无消费', value: 1 }],
      // ruleList: [{ label: '有消费', value: 1 }, { label: '有预约', value: 2 }, { label: '有登录', value: 3 }],
      ruleName: '',
      ruleValue: '',
      ruleTime: ''
    }
  },
  created () {
    this.init()
  },
  methods: {
    init () {
      const params = {
        ugname: this.gcname,
        page_index: this.page,
        page_size: this.size
      }
      customerGroupQuery(params).then(res => {
        if (res.status === 1) {
          this.groupList = res.data.data
        } else {
          this.groupList = []
        }
      })
    },
    handleSearch () {
      this.init()
    },
    handleReset () {
      this.gcname = ''
      this.init()
    },
    // 点击添加
    handleAdd () {
      this.formShow = true
      this.formTitle = '新增'
      this.groupForm.ugid = ''
    },
    // 取消
    formCancel () {
      this.formShow = false
      this.$refs.groupLabel.resetFields()
    },
    // 点击【规则设置】
    handEdit (row) {
      this.formShow = true
      this.formTitle = '编辑'
      this.groupForm = { ...row }
    },
    changeRule (val) {
      this.ruleName = this.ruleList[val - 1].label
    },
    // 规则添加
    ruleAdd () {
      if (this.ruleValue === '') {
        this.$message.warning('请选择规则')
      } else if (this.ruleTime === '') {
        this.$message.warning('请输入天数')
      } else {
        const obj = { name: this.ruleName, type: this.ruleValue, time: this.ruleTime }
        if (this.groupForm.ugset.length < 3) {
          this.groupForm.ugset.push(obj)
          this.ruleName = ''
          this.ruleValue = ''
          this.ruleTime = ''
        }
      }
    },
    // 规则删除
    ruleDel (eq) {
      this.groupForm.ugset.splice(eq, 1)
    },
    // 表单【新增-编辑】提交
    groupConfirm () {
      this.$refs.groupLabel.validate((valid) => {
        if (!valid) return
        const params = {
          ugid: this.groupForm.ugid,
          ugname: this.groupForm.ugname,
          ugmark: this.groupForm.ugmark,
          ugset: this.groupForm.ugset
        }
        if (this.formTitle === '新增') { // 新增提交
          customerGroupAdd(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.formCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        } else if (this.formTitle === '编辑') {
          customerGroupEdit(params).then(res => {
            if (res.status === 1) {
              this.$message.success(res.message)
              this.formCancel()
              this.init()
            } else {
              this.$message.error(res.message)
            }
          })
        }
      })
    },
    // 删除
    handDel (row) {
      this.$confirm('此操作将永久删除该分群, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
        center: true
      }).then(() => {
        customerGroupDel({ ugid: row.ugid }).then(res => {
          if (res.status === 1) {
            this.$message.success(res.message)
            this.init()
          } else {
            this.$message.error(res.message)
          }
        })
      }).catch(() => {})
    }
  }
}
</script>

<style scoped>
.card_list{
  width: 100%;
  height: calc(100% - 158px);
  overflow-y: scroll;
  scrollbar-width: none;
}
.card_list::-webkit-scrollbar {
  width: 0;
}
.cardBox {
  width: 300px;
  background: #F7F9FB;
  margin-right: 16px;
  margin-bottom: 16px;
  box-sizing: border-box;
  font-size: 14px;
}
.cardTitle{
  color: #A5B7CC;
  margin-bottom: 5px;
}
</style>
